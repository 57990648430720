.addPanel {
    text-align: center;
    flex-direction: column; /* Elementy będą ułożone kolumnowo */
    align-items: center;
    background: #fff;
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    align-self: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
    position:absolute;
font-size: large;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ThreeStep {
    width: 70%;
    position: absolute;
    top: 50%;
    z-index:1 ;
    overflow-y: auto; /* Dodaj tę linię */
}
.photo {
  width: 100px; /* ustal szerokość kwadratu */
  height: 100px; /* ustal wysokość kwadratu */
  object-fit: cover; /* zachowaj proporcje i wypełnij całą przestrzeń */
  border-radius: 10px;

}

 .Up {
    top: 75%;
    left: 50%;
    width:85%;
    transform: translate(-50%, -50%);
    z-index: 10;
    position:fixed;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .modal {
    z-index: 11; 
       width:90%;
    height:90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position:absolute;
  }
  select {
    width: 25%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    margin-bottom: 20px;
  }
  .photosel {
    scale:100%;
  }
  select:focus {
    border-color: #007bff;
  }


  .button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  

@media only screen and (max-width: 600px) {
  .infosettings{
    width:350px;
  }
}
