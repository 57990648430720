@import url('variables.css');

.meal-view {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background: var(--background-color);
    color:var(--text-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 600px;
    align-items: center;
  
}

.meal-view h2 {
    font-size: 2em;
    color: var(--button-background);
    margin-bottom: 20px;
}

.meal-view p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.meal-view .product-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--text-color);
    margin: 10px;
    padding: 10px;
    width: 70%;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.meal-view .product-item h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.meal-view .product-item p {
    margin-bottom: 10px;
}

.meal-view .product-item button {
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.meal-view .product-item button:hover {
    background-color: var(--nav-link-bg-active);
}

.meal-view a {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.meal-view a:hover {
    background-color: #0056b3;
}

.product-remove-btn {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #dc3545;
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.product-remove-btn:hover {
    background-color: #c82333;
}

.meal-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--text-color);
    margin: 10px;
    padding: 10px;
    width: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}



.calculator input {
    margin: 10px;
    padding: 10px;
    width: 70%;
    border: none;
    color:var(--text-color);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(9, 177, 255, 0.1);
}

.calculator button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .meal-view {
        width: 70%; /* Adjust the width as needed */
    }
}

@media only screen and (min-width: 1025px) {
    .meal-view {
        width: 25%;
        max-width: 800px;
    }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
    .meal-view {
        width: 90%; /* Adjust the width as needed */
    }
}
@media only screen and (max-width: 767px) and (orientation:portrait) {
   .meal-product{
    width:200px;
   }
}
/* Hide scanner on devices with screen width between 768px and 1024px */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .scannerbtn {
      display: none;
    }
  }
  
  /* Hide scanner on devices with screen width greater than or equal to 1025px */
  @media only screen and (min-width: 1025px) {
    .scannerbtn {
      display: none;
    }
  }
  