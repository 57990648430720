@import url('variables.css');
.product-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  overflow-y: scroll;
  background: var(--background-color);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 11;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.addbtn {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: var(--button-background);
  color: var(--button-text-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block; /* Dodano, aby przycisk był blokiem */
  margin-top: 20px; /* Dodano, aby uzyskać odstęp od innych elementów */
}


.target {
  z-index:1;
}
.product-popup img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.errormessage {
  color:red;
  text-decoration: double;
  background-color: var(--background-color);
  z-index: 3;
  position: fixed;
  border-radius: 5px;
  font-size: large;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}