@import url('variables.css');

.adminoptions {
  display: flex; /* Użycie układu flexbox */
  justify-content: center; /* Wyrównanie elementów do środka w osi poziomej */
  align-items: center; /* Wyrównanie elementów do środka w osi pionowej */
  width: 100%; /* Szerokość 100% ekranu */
  height: 50px; /* Wysokość 50 pikseli */
  position: absolute; /* Pozycjonowanie bezwzględne */
  bottom: 0; /* Umieszczenie na dole ekranu */
  background-color: var(--text-color); /* Szary kolor tła */
  padding: 10px 0; /* Wypełnienie z góry i z dołu */
  z-index: 10; /* Indeks warstwy */
}

.infohome {
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background: var(--desktop-info-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
 
  display: grid;
}

.admin-options p {
  color: var(--text-color);
}

.btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: white;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 50%;
  max-width: 600px;
  background: var(--desktop-info-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow-y: auto;
}

.modalcontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.desktop-info {
  position: absolute;
  display: none;
  top: 50px;
  right: 20px;
  margin: 20px;
  padding: 20px;
  height: 84%;
  width: 200px;
  background-color: var(--desktop-info-background);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.desktop-info h2 {
  color: #333;
}

.desktop-info p {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
}

.add-section,
.barcode-section,
.search-section,
.meal-section {
  align-self: center;
  padding: 10px 10px 10px 10px;
  width: 80%;
  height: 50px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 20px;
  text-decoration: none;
  color: var(--button-text-color);
  background-color: var(--ui_background);
  margin: 15px;
}

a {
  text-decoration: none;
}

.home {
  width: 30px;
  height: 30px;
  padding: 5px;
}

.scan {
  width: 30px;
  height: 30px;
  padding: 4px;
}

.searchbtn {
  padding: 4px;
}

.search-section p {
  font-size: 12.5px;
}

@media only screen and (max-width: 767px) {
  .add-section,
  .barcode-section,
  .search-section,
  .meal-section {
    width: 90%;
  }
}

@media only screen and (max-width: 740px) {
  .infohome {
    overflow-x: hidden;
    scale: 98%;
    transform: translate(-50%, -55%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .infohome,
  .modal {
    width: 70%;
  }

  .add-section,
  .barcode-section,
  .search-section,
  .meal-section {
    width: 70%;
  }
}

@media only screen and (min-width: 1025px) {
  .infohome,
  .modal {
    width: 80%;
  }

  .button-container {
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 100%;
     /* Add this to create space between buttons */
  }

  .add-section,
  .search-section,
  .meal-section {
    width: auto;
  }

  .barcode-section {
    display: none;
  }

  .desktop-info {
    display: block;
  }
}
