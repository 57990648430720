/* variables.css */

:root {
    --background-color: #ffffff;
    --text-color: #000000;
    --panel-background: url("cukrzyca.jpg");
    --infohome-background: #ffffff;
    --modal-background: #ffffff;
    --desktop-info-background: #f8f8f8;
    --desktop-info-text-color: #333;
    --desktop-info-p-color: #555;
    --button-background: #007BFF;
    --button-text-color: #ffffff;
    --close-color: #aaa;
    --close-hover-color: #000;
    --ui_background:#3498db;
    --nav-link-bg-active: #007BFF;
    --select-bg:#ffffff;
    --border-color:#ccc;
  
  }
  
  body.dark-mode {
    --background-color: #171717;
    --text-color: #ffffff;
    --panel-background: url("cukrzyca-dark.jpg");
    --infohome-background: #2f2f2f;
    --modal-background: #333333;
    --desktop-info-background: #141414;
    --desktop-info-text-color: #ffffff;
    --desktop-info-p-color: #aaaaaa;
    --button-background: #099dff;
    --button-text-color: #ffffff;
    --close-color: #ffffff;
    --close-hover-color: #ffffff;
    --ui_background:#085b93;
    --nav-link-bg-active: #3781d1;
    --border-color:#00000000;
    --select-bg:#099dff;
  }
  