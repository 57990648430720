/* Common styles for both landscape and portrait modes */

@import url('variables.css');

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}


.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.internet-status {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 1000;
  border-radius: 5px;
  padding: 10px 20px;
  color: var(--text-color);
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.internet-status .close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  color: var(--button-text-color);
}

.bottom-nav {
  margin-top: auto;
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  background-color: var(--ui_background);
  padding: 10px;
  z-index: 10;
  gap:20px;
}

.bottom-nav button {
  background: none;
  border: none;
  color: white;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  padding: 12px 0; /* Zmniejszenie paddingu poziomego */
  margin: 0;
  width:50px;
  height:50px;
  border: none;
  background-color: var(--ui_background);
  transition: background-color 0.3s;
  display: flex;
  flex-direction: column; /* Ustawienie kolumnowo */
  align-items: center; /* Wyśrodkowanie w pionie */
  justify-content: center;
font-size: auto;
}

.nav-link.active {
  background-color: var(--nav-link-bg-active);
  border-radius: 5px;
  width:50px;
  height:50px;
}



.nav-link .ion {
  margin-bottom: 5px; /* Odległość między ikoną a tekstem */
}



.logo {
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--ui_background);
  color: white;
  padding: 10px;
  z-index: 10;
}

.logo a {
  text-decoration: none;
  color: white;
}

.logo img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.panel {
  background-image: var(--panel-background);

  height: 100vh; /* Cover the full viewport height */
  width: 100vw; /* Cover the full viewport width */
  overflow: hidden; /* Hide overflow */
  background-size: cover; /* Ensure the background image covers the entire panel */
  background-position: center; /* Center the background image */
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  display: flex; /* Align items if necessary */
 /* Center content horizontally if necessary */
   /* Center content vertically if necessary */
  background-color: var(--background-color); /* Set background color */
  z-index: 0;
}



.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background: var(--desktop-info-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.logo p {
  font-size: 20px;
}

.settings-btn {
  border: none;
color:white;
background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 10px;
}
.refreshicon {
  font-size: xx-large;
  color:white;
}
.refresh-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
 left:20px;
}
/* Hide scanner on devices with screen width between 768px and 1024px */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .scanner {
    display: none;
  }

}

/* Hide scanner on devices with screen width greater than or equal to 1025px */
@media only screen and (min-width: 1025px) {
  .scanner {
    display: none;
  }
}
