/* Polityka.css */

  
  .infopolicy {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 800px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-height: 500px;
  }
  
  
  .zakladkip {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  hr {
    border: 1px solid #ddd;
    margin: 20px 0;
  }
  
  ol {
    margin-left: 20px;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  strong {
    font-weight: bold;
  }
  