/* Import global variables */
@import url('variables.css');

/* Container for product search */
.product-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    font-family: Arial, sans-serif;
    color: var(--text-color);
    box-sizing: border-box;
}



/* Search input and button */
.search {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--border-color);
    margin: 10px;
    padding: 20px;
    width: 200px;
    background: var(--background-color);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.product-search input {
    margin: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    border: none;
    color: var(--text-color);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: rgba(9, 177, 255, 0.1);
}

.product-search button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: var(--button-background);
    color: var(--button-text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.product-search button:hover {
    background-color: #0056b3;
}

/* Product list container */
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;
    height: auto;
    
    max-height: calc(100vh - 60px);
    width: 100%;
    background: rgba(255, 255, 255, 0);
}

/* Individual product item */
.product-item, .notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--text-color);
    margin: 10px;
    padding: 10px;

    width: calc(100% - 20px);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Placeholder for no product information */
.infonone {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    padding: 10px;
    height: auto;
    width: calc(100% - 20px);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--button-background);
    font-size: 15px;
    color: var(--text-color);
}

.infonone a {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #4adb11;
    color: var(--text-color);
    text-decoration: none;
}

.product-item img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.product-item table {
    width: 100%;
    text-align: left;
    margin-top: 10px;
}

.product-item table th, .product-item table td {
    padding: 5px;
}

/* Responsive design for iPad landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .product-search {
        padding: 20px;
    }

    .search {
        width: 80%;
    }

    .product-search input {
        width: calc(100% - 30px);
    }

    .product-search button {
        width: 100%;
    }

    .product-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        padding: 15px;
    }

    .product-item {
        width: 100%;
    }
}

/* Responsive design for desktop */
@media only screen and (min-width: 1025px) {
    .product-search {
        padding: 40px;
    }

    .search {
        width: 50%;
    }

    .product-search input {
        width: calc(100% - 40px);
    }

    .product-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 15px;
        padding: 30px;
        width: 100%;
        max-height: 500px;
    }

    .product-item {
        width: calc(100% - 20px);
    }
}

/* Responsive design for phone landscape */
@media only screen and (max-width: 767px) and (orientation: landscape) {
    .product-search {
        padding: 15px;
    }

    .search {
        width: 70%;
    }

    .product-search input {
        width: calc(100% - 20px);
    }

    .product-search button {
        width: 100%;
    }

    .product-list {
        padding: 10px;
    }

    .product-item {
        width: 100%;
    }
}

/* Responsive design for iPad portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .product-search {
        padding: 20px;
    }

    .search {
        width: 80%;
    }

    .product-search input {
        width: calc(100% - 30px);
    }

    .product-search button {
        width: 100%;
    }

    .product-list {
        padding: 15px;
        max-height: 900px;
    }

    .product-item {
        width: 80%;
    }
}
