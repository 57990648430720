.infoterms {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-height: 500px; /* Include padding in width/height calculation */
}



.sectionTitle {
  color: #333;
}
.sectionTitle{
  height: fit-content;
}
.paragraph {
  color: #666;

}

.bold {
  font-weight: bold;
}

.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

