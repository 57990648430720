/* LandingPage.css */
.landing-page {
  max-width: 100%;
  max-height: 100%;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  background-image: url('cukrzyca.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.imgks {
  width: 200px; /* dowolna wartość szerokości */
  height: 200px; /* ustalona szerokość, aby uzyskać kwadratowe zdjęcie */
  border-radius: 20px; /* promień zaokrąglenia */
  object-fit: cover;
  align-self: center; /* zapewnia, że zdjęcie będzie wypełniać całe pole, zachowując proporcje */
}
.link {
  text-decoration: none;
  color:blue;
}
.social-media {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-link {
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.social-link:hover {
  background-color: #007bff; /* Adjust this color as needed */
  color: white;
}

.social-link svg {
  width: 24px;
  height: 24px;
  align-self: center;
}
.landinginfo {
  align-items: center;
  text-align: center;
  overflow-y: auto;
  margin: 160px auto;
  width: calc(100% - 40px);
  max-width: 600px;
  background: #fff;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: grid;
}

.top-bar {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  position: relative;
}

.top-bar img {
  width: 40px;
  margin-right: 10px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

.landing-header {
  text-align: center;
  padding: 150px 20px 50px;
}

.diabcalc-image {
  width: 200px;
}
.selectlanguage{
  background-color: white;
  color: black;
}

.landing-title {
  font-size: 32px;
  margin-bottom: 20px;
}

.downloadtitle {
  font-size: 20px;
  margin-bottom: 20px;
  align-self: center;
  text-align: center;
  font-weight: bold;
}

.landing-description {
  font-size: 18px;
}

.features-section {
  text-align: center;
  padding: 50px 20px;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.features-image {
  width: 100%;
  margin-bottom: 20px;
}

.features-description {
  font-size: 18px;
}

.landing-nav {
  text-align: center;
  margin-top: 30px;
}

.landing-link {
  margin: 0 10px;
  font-size: 18px;
}

.landing-footer {
  background-color: #3498db;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.footer-image {
  width: 200px;
  margin-bottom: 20px;
}

.footer-text {
  font-size: 16px;
}

.open-web-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 30px auto;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.open-web-btn:hover {
  background-color: #004987;
}

.zwolnieni {
  text-decoration: none;
  color: rgb(255, 0, 255);
  font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
  .landing-header {
    padding: 100px 20px 30px;
  }

  .landinginfo {
    margin: 80px auto;
    width: calc(100% - 20px);
    padding: 10px;
  }

  .landing-title {
    font-size: 24px;
  }

  .landing-description,
  .features-description,
  .section-title,
  .footer-text,
  .landing-link,
  .open-web-btn {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .landing-header {
    padding: 70px 10px 20px;
  }

  .landinginfo {
    margin: 60px auto;
    width: calc(100% - 10px);
    padding: 5px;
  }

  .landing-title {
    font-size: 20px;
  }

  .landing-description,
  .features-description,
  .section-title,
  .footer-text,
  .landing-link,
  .open-web-btn {
    font-size: 14px;
  }
}

/* Navbar styles */

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  position: relative;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color:white;
}

.logolanding {
  width: 40px;
  margin-right: 10px;
}

.diabcalc-title {
  font-size: 24px;
 
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}
.nav-links a.active {
  background-color: #007bff; /* Adjust this color as needed */
  color: white;
  border-radius: 5px;

}

.img {
  width:70%;
  height:70%;
}
@media (max-width: 768px) {
  .landinginfo {
    width:80%;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 60px;
    left: -100%; /* Start off-screen */
    background-color: #3498db;
    width: 100%;
    height: 100%;
    transition: left 0.3s ease; /* Smooth transition */
    z-index: 10;
    font-size: large;
    text-align: left;
  }

  .nav-links.open {
    left: 0; /* Slide in on the left */
  }

  .nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    padding: 10px 20px;
  }

  .menu-icon {
    display: block;
  }

}
